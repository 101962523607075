<template>
  <div v-loading="loading" :element-loading-text="loadingText">
    <div class="outer-div mb-1">
      <div class="head-title text-dark">All entity views</div>
      <div :style="getIsMobile ? 'margin-top: 30px' : 'margin-top:10px'">
        <el-input
          class="mr-1"
          size="small"
          prefix-icon="el-icon-search"
          placeholder="Search"
          v-model="search_string"
          clearable
          :style="getIsMobile ? 'width:130px' : 'width:250px;'"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getNecessaryInfo"
            size="mini"
          ></el-button>
        </el-input>
        </div>
      <el-button type="primary" @click="createNewView" :style="getIsMobile ? 'margin-top:30px' : 'margin-top:10px'">
        <span class="create-entity-view">
        <i class="fa fa-plus-circle"></i> 
        Create
      </span>
      </el-button
      >
    </div>
    <div
      class="vue-data-table-default"
      v-loading="tableLoading"
      :element-loading-text="loadingText"
    >
      <data-tables-server
        :fullscreen="true"
        :total="total"
        :data="data"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        style="width: 100%"
        v-if="!getIsMobile"
        @query-change="loadData"
        @sort-change="sortChange"
      >
        <el-table-column
          label="Name"
          id="name"
          prop="title"
          width="500"
          fixed="left"
          sortable="custom"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row.name"
            >
              <el-button
                slot="reference"
                type="text"
                @click="onEdit(scope.row)"
              >
                <div style="color: #4c4b4e; font-weight: 400">
                  {{ scope.row.name }}
                  <i
                    class="el-icon-star-on"
                    v-if="scope.row.entity_type === 'BUSINESS'"
                  ></i>
                  <i
                    class="el-icon-user"
                    v-if="scope.row.entity_type === 'INDIVIDUAL'"
                  ></i>

                  <i
                    class="el-icon-magnet"
                    v-if="scope.row.entity_type === 'GENERAL'"
                  ></i>
                </div>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="Actions"
          prop="action"
          class="action"
          width="200"
        >
          <template slot-scope="scope">
            <ul class="action-buttons hover-buttons">
              <li>
                <el-dropdown>
                  <el-button size="mini" class="el-dropdown-link px-2">
                    Actions
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <a @click="onView(scope.row)" style="border: none">
                      <el-dropdown-item icon="el-icon-view" command="view"
                        >View</el-dropdown-item
                      >
                    </a>
                    <a @click="onEdit(scope.row)" style="border: none">
                      <el-dropdown-item
                        icon="el-icon-edit-outline"
                        command="edit"
                        >Edit</el-dropdown-item
                      >
                    </a>
                    <a @click="onDelete(scope.row)" style="border: none">
                      <el-dropdown-item icon="el-icon-delete" command="delete"
                        >Delete</el-dropdown-item
                      >
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Status"
          id="status"
          prop="title"
          width="200"
          sortable="custom"
        >
          <template slot-scope="scope" id="category">
            <div class="d-flex activestatus">
              <div :class="scope.row.status.toLowerCase() + ' circle'"></div>
              <div>
                <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                <p v-else>Deleted</p>
              </div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Last Modified"
          prop="last_modified"
          sortable="custom"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.updated_at | globalDateTimeFormat }}
          </template>
        </el-table-column>
      </data-tables-server>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import { fetchEntityViews, deleteEntityView } from "@/repo/entityViewsRepo";
export default {
  mixins: [NavigationHelper],
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entityViews", ["getAllEntityViews"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entityViews", ["getDeleteEntityViewStatus"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      order_by: "",
      order_type: "",
      data: [],
      loadingText: "Fetching data...",
      search_string : ""
    };
  },
  mounted() {
    this.loading = true;
    this.getNecessaryInfo();
    this.loading = false;
  },
  methods: {
    async onView(document) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "EntityViews",
        params: {
          entity_view_id: document._id,
        },
        query: query,
      });
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.getNecessaryInfo();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.getNecessaryInfo();
      }
    },
    async loadData(data) {
      if ((data && data.type != "init") || !data) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.getNecessaryInfo();
      }
    },

    async getNecessaryInfo() {
      this.tableLoading = true;
      this.loadingText = "Fetching data...";
      let params = {
        limit: this.pageSize > 0 ? this.pageSize : 10,
        page: this.page,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      // await this.$store.dispatch("entityViews/getAllEntityViews", params);
      const getAllEntityViews = await fetchEntityViews(params);
      if (getAllEntityViews) {
        this.data = [...getAllEntityViews.data];
        this.total =
          getAllEntityViews && getAllEntityViews.total
            ? getAllEntityViews.total
            : 0;
        this.currentPage = parseInt(getAllEntityViews.page) || 1;
      }
      this.tableLoading = false;
    },
    createNewView() {
      this.$router.push({
        name: "EntityViewAdd",
      });
    },
    onEdit(row) {
      this.$router.push({
        name: "EntityViewEdit",
        params: {
          entity_view_id: row._id,
        },
      });
    },
    async onDelete(view) {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteView(view);
      });
    },
    async deleteView(view) {
      this.loading = true;
      this.loadingText = "Deleting...";
      await this.$store.dispatch("entityViews/deleteEntityViewById", view._id);
      if (this.getDeleteEntityViewStatus) {
        await deleteEntityView(view._id);
        this.$notify.success({
          title: "Success",
          message: "Entity view deleted successfully",
        });
        this.getNecessaryInfo();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error while deleting entity view",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  color: var(--primary-color);
  background: var(--primary-contrast-color);
  border-color: var(--primary-color);
  &:hover {
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
}
.outer-div {
  display: flex !important;
  justify-content: space-between !important;
}
.head-title {
  font-size: 18px;
  margin-top:10px;
  @media (max-width:767px){
    margin-top:30px;
  }
}
.el-button--primary {
  color: var(--primary-contrast-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  height: 32px !important;
}
.create-entity-view {
  position: relative;
  bottom: 3px;
}
</style>
